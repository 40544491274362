$('.slider__options button').on('click', function(){
  let id = $(this).attr('data-id');
  if($('html').attr('lang') == 'en'){
    $('.slider__dotted--silver').html(bussinesModel[id].topen);
    $('.slider__dotted--orange').html(bussinesModel[id].bottomen);
  }else{
    $('.slider__dotted--silver').html(bussinesModel[id].top);
    $('.slider__dotted--orange').html(bussinesModel[id].bottom);
  }
  $('.slider__options button').removeClass('active');
  for(let i = 0; i < 4; i++){
    $('.slider__graphics--' + bussinesModel[i].class).css({'display':'none'});
  }
  if(!$(this).hasClass('active')){
    $(this).addClass('active');
    $('.slider__graphics--' + bussinesModel[id].class).css({'display':'block'});
  }
});

$('.bm-svg').on('click', function(){
  let id = $(this).attr('data-id');
  if($('html').attr('lang') == 'en'){
    $('.slider__dotted--silver').html(bussinesModel[id].topen);
    $('.slider__dotted--orange').html(bussinesModel[id].bottomen);
    $('.bm-svg').next().html(bussinesModel[id].nameen).css({'opacity':'0'});
  }else{
    $('.slider__dotted--silver').html(bussinesModel[id].top);
    $('.slider__dotted--orange').html(bussinesModel[id].bottom);
    $('.bm-svg').next().html(bussinesModel[id].name).css({'opacity':'0'});    
  }
  $('.bm-svg').prev().css({'opacity':'0'});
  $('.bm-svg').next().next().css({'opacity':'0'});
  $('.bm-svg').removeClass('active');
  if(!$(this).hasClass('active')){
    $(this).addClass('active');
    if($('html').attr('lang') == 'en'){
      $(this).next().html(bussinesModel[id].nameen).css({'opacity':'1'});
    }else{
      $(this).next().html(bussinesModel[id].name).css({'opacity':'1'});
    }
    $(this).prev().css({'opacity':'1'});
    $(this).next().next().css({'opacity':'1'});
  }
});

const bussinesModel = [
  {
    class:'one', 
    name:'surowce i zasoby naturalne', 
    top:'<div><span>200 tys. ton</span> <span>zakupionych surowców</span></div><div><span>893 tys. GJ </span> <span>(gigadżuli) zużytej energii elektr.</span></div><div><span>524 tys. GJ</span> <span>zużytego gazu ziemnego</span></div><div><span>762 tys.</span> <span>m sześc. zużytej wody</span></div>', 
    bottom:'<p>29.5 tys. ton złomu aluminium poddanego recyklingowi</p>  <p>o 7% r/r poprawa efektywności zużycia energii elektrycznej na jednostkę produkcyjną</p><p>o 11% r/r wzrost wielkości emisji gazów cieplarnianych na jednostkę produkcji</p><p>o 3% r/r zmniejszenie zużycia wody na jednostkę produkcji</p>', 
    nameen:'raw materials and natural resources', 
    topen:'<div><span>200,000 tonnes</span> <span>of purchased raw materials</span></div><div><span>893,000 GJ </span> <span>of consumed electricity</span></div><div><span>524,000 GJ</span> <span>of consumed natural gas</span></div><div><span>762,000 m<sup>3</sup></span> <span>of consumed water</span></div>', 
    bottomen:'<p>29,500 tonnes of recycled aluminium scrap</p>  <p>Improvement of electricity consumption efficiency per production unit by 7% y/y</p><p>Higher greenhouse gas emissions per production unit by 11% y/y</p><p>Lower water consumption per production unit by 3% y/y</p>'
  },
  {
    class:'two', 
    name:'aktywa produkcyjne', 
    top:'<div><span>Majątek trwały  (budynki, maszyny)</span> <span>1 337 mln zł</span></div><div><span>Majątek obrotowy (zapasy, należności)</span> <span>1 205 mln zł</span></div><div><span>Wydatki inwestycyjne</span> <span>231 mln zł</span></div>', 
    bottom:'<p>Przychody ze sprzedaży 3,0 mld zł</p><p>97% wykorzystanych mocy produkcyjnych</p><p>Środki wypłacone dostawcom maszyn i firmom realizującym inwestycje budowlane - 231 mln zł</p><p>Zwrot z aktywów (ROA) na poziomie 10%</p>', 
    nameen:'production assets', 
    topen:'<div><span>Property, plant and equipment of</span> <span>PLN 1,337 mill.</span></div><div><span>Current assets (inventories, receivables) of </span> <span>PLN 1,205 million</span></div>  <div><span>Capital expenditure of </span> <span>PLN 231 million</span></div>', 
    bottomen:'<p>PLN 3.0 billion sales revenue</p><p>97% production capacity utilisation</p><p>PLN 231 million paid to machine suppliers and property developers</p><p>10% Return on Assets (RoA)</p>'
  },

  {
    class:'three', 
    name:'ludzie wiedza i relacje', 
    top:'<div><span>5146 pracowników</span></div><div><span>kobiety</span> <span>26%</span></div><div><span>zatrudnienie na czas nieokreślony</span> <span>76%</span></div><div><span>klientów</span> <span>4200</span></div><div><span>dostawców</span> <span>3000</span></div><p>Centrum Badawczo-Rozwojowe, <b>5 laboratoriów</b> ponad <b>100 pracowników R&D</b></p>', 
    bottom:'<p>Wraz z wiekiem wzrasta doświadczenie, wiedza praktyczna, mądrość = przekłada się na innowacyjność, kreatywność</p><p>Poczucie bezpieczeństwa = lojalność wobec firmy</p><p>7,2 godziny szkolenia na jednego pracownika</p><p>2458 uruchomionychy matryc pod nowe profile</p>',nameen:'people, expertise and relations', 
    topen:'<p><span>5,146 employees</span></p><div><span>women</span> <span>26%</span></div><div><span>staff employed for unlimited term</span> <span>72%</span></div><div><span>customers</span> <span>4,200</span></div><div><span>suppliers</span> <span>3,000</span></div><p>Research and Development Centre, <b>5 laboratories</b> over <b>100 R&D employees</b></p>', 
    bottomen:'<p>Experience, expertise and wisdom grow with age, which translates into innovation and creativity</p><p>Sense of security translates into loyalty to the company</p><p>7.2 hours of training per employee</p><p>2,458 new profile dies introduced</p>'
  },

  {
    class:'four', 
    name:'finanse', 
    top:'<div><span>Kapitał własny</span> <span>1397 mln zł</span></div><div><span>Zobowiązania bilansowe</span> <span>1383 mln zł</span></div><div><span>Pomoc publiczna - zwolnienie z pod. dochodowego</span> <span>33,2 mln zł</span></div>', 
    bottom:'<p>Dywidenda wypłacona akcjonariuszom - 229 mln zł</p><p>Zapłacony w kraju podatek dochodowy - 57,0 mln zł</p><p>Zwrot z kapitału własnego (ROE)na poziomie 19%</p><p>Zapłacony w Polsce podatek VAT - 142 mln zł</p>',
    nameen:'finances',  
    topen:'<div><span>Equity of </span> <span>PLN 1,397 millon</span></div><div><span>Balance - sheet liabilities of</span> <span>PLN 1,383 mill.</span></div><div><span>Public aid (income tax exemption) of</span> <span>PLN 33.2 millon</span></div>', 
    bottomen:'<p>PLN 229 million dividend paid to shareholders</p><p>PLN 57 million income tax paid in Poland</p><p>19% Return on Equity (RoE)</p><p>PLN 142 million VAT paid in Poland</p>'
  }
]
