window.onload = function(){
    if('cookiesAccepted' in localStorage) {
        $('.cookies').css('display', 'none');
    } else {
        $('.cookies').css('transform', 'scale(1,1)');
    }

    $('.cookies__btn').on('click', function(){
        localStorage.setItem('cookiesAccepted', true);
        $('.cookies').css('transform', 'scale(1,0)');
    });

    if(window.location.href.indexOf("en") > -1) {
        $('.cookies-pl').hide();
     } else {
        $('.cookies-en').hide();         
     }
}



