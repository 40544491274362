progressBar();

$(window).resize(function() {
  progressBar();
});

function progressBar(){
  let marginLeft = 0;
  if($(window).outerWidth(true) > 1199) {
    marginLeft = -($(window).height()/2) + $('.header').innerWidth();
  }
  $('progress').css({'left':marginLeft+'px'}).fadeIn();

  let winHeight = $(window).height(), 
  docHeight = $(document).height(),
  progressBar = $('progress'),
  max, value;

  
  max = docHeight - winHeight;
  progressBar.attr('max', max);
  
  if(max !== 0) {
    $(document).on('scroll', function(){
      value = $(window).scrollTop();
      progressBar.attr('value', value);
    });
  } else {
    progressBar.attr('value', 1);
  }

}

export default progressBar;
