import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

const amountEl = $('.numbers-in-box__item').length;

if(amountEl > 3) {
  $('.numbers-in-box__item').css('width','100%');
  $('.numbers-in-box__items').addClass('owl-carousel');
  checkWidth();
}

function checkWidth(){
  if ($(window).width() > 960) {
    $('.numbers-in-box__items.owl-carousel').owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      dots: true,
      items: 3,
      autoplay: true,
      smartSpeed: 3000,
      autoplayTimeout: 5000,
    });
 } else {
  $('.numbers-in-box__items.owl-carousel').owlCarousel('destroy');
 }
}

$(window).on('resize', checkWidth);
$(document).ready(checkWidth);

