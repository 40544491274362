import PerfectScrollbar from 'perfect-scrollbar';

const ps = new PerfectScrollbar('nav');

menu('.nav__level--one', true);
menu('.nav__level--two', false);
displayNav();

$('.hamburger').on('click', function() {
  if(!$(this).hasClass('hamburger--active')) {
    $(this).addClass('hamburger--active');
    $('.nav').slideDown(600);
  } else {
    $(this).removeClass('hamburger--active');
    $('.nav').slideUp(600);
  }
});

$(window).resize(function(){
  displayNav();
});

function displayNav() {
  ($(window).outerWidth(true) > 1199) ? $('.nav').css('display','flex') : $('.nav').css('display','none');
}

function menu(className, siblings) {
  $(className).on('click', function(e) {
    e.stopPropagation()
    const target = $(this).attr('data-target');
    if(!$(this).hasClass('active')) {
      if(siblings === true) {
        $('.nav__level--two').removeClass('active');
        $('.nav__level--two').children('ul').slideUp(400);
      }
      $(className).removeClass('active');
      $(className).children('ul').slideUp(400);
      $(this).addClass('active');
      $('#' + target).slideDown(400);
    } else {
      $(this).removeClass('active');
      $('#' + target).slideUp(400);
    }
  });
}
